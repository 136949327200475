
.v-loader[data-v-683ed080] {
  z-index: 100000;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.v-loader > .loader-container[data-v-683ed080] {
  display: block;
  opacity: 1;
  background-color: rgba(255,255,255,0.1);
}
